/**
 * @generated SignedSource<<c87a42611e776bc39b25250bc5c2f7eb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BlogArticleEditPageFragment$data = {
  readonly authors: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
      };
    }>;
  };
  readonly content: string | null | undefined;
  readonly id: string;
  readonly image: any | null | undefined;
  readonly seoDescription: string | null | undefined;
  readonly seoTitle: string | null | undefined;
  readonly shortDescription: string;
  readonly title: string;
  readonly topic: {
    readonly author: {
      readonly id: string;
    };
  };
  readonly " $fragmentSpreads": FragmentRefs<"BlogArticleDeleteButtonFragment">;
  readonly " $fragmentType": "BlogArticleEditPageFragment";
};
export type BlogArticleEditPageFragment$key = {
  readonly " $data"?: BlogArticleEditPageFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"BlogArticleEditPageFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BlogArticleEditPageFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "content",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "image",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shortDescription",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "seoTitle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "seoDescription",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BlogArticleAuthorConnection",
      "kind": "LinkedField",
      "name": "authors",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "EntityEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Topic",
      "kind": "LinkedField",
      "name": "topic",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "author",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BlogArticleDeleteButtonFragment"
    }
  ],
  "type": "BlogArticle",
  "abstractKey": null
};
})();

(node as any).hash = "4155b7ef4640adb7830f5ffdc83dbe85";

export default node;
