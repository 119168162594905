import { graphql, Environment } from "react-relay";

import { preload, usePreloaded } from "../../utils/relay";
import { BlogArticlesPageQuery as BlogArticlesPageQueryType } from "./__generated__/BlogArticlesPageQuery.graphql";

export const BlogArticlesPageQuery = graphql`
  query BlogArticlesPageQuery {
    viewer @ifAllowed {
      canCreateArticle: can(action: CREATE_BLOG_ARTICLE)
    }

    ...BlogArticlesFragment
  }
`;

export const blogArticlesPageQueryLoader = (environment: Environment) => {
  return () => {
    return preload<BlogArticlesPageQueryType>(
      environment,
      BlogArticlesPageQuery,
    );
  };
};

export const useBlogArticlesPageQuery = () =>
  usePreloaded<BlogArticlesPageQueryType>();
