import { FormattedMessage, useIntl } from "react-intl";
import { graphql, useMutation } from "react-relay";
import { useBlogArticleCreatePageQuery } from "./loaders/BlogArticleCreatePage";
import BlogArticleEditForm from "../components/BlogArticleEditForm";
import { ConnectionHandler, UploadableMap } from "relay-runtime";
import {
  BlogArticleCreatePageMutation,
  BlogArticleCreatePageMutation$variables,
} from "./__generated__/BlogArticleCreatePageMutation.graphql";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { logger } from "../common/logger";
import { getBlogArticlePath } from "../utils/routing";

const Mutation = graphql`
  mutation BlogArticleCreatePageMutation(
    $input: CreateBlogArticleInput!
    $connections: [ID!]!
  ) {
    createBlogArticle(input: $input) @prependEdge(connections: $connections) {
      node {
        id
        title
        ...BlogArticleFragment
        ...BlogArticleCardFragment
      }
    }
  }
`;

export default function BlogArticleCreatePage() {
  const intl = useIntl();
  const navigate = useNavigate();
  const { query } = useBlogArticleCreatePageQuery();
  const [commitMutation, isMutationInFlight] =
    useMutation<BlogArticleCreatePageMutation>(Mutation);

  return (
    <div>
      <h1 className="text-xl font-bold pb-4 font-poppins">
        <FormattedMessage defaultMessage="Create new blog article" />
      </h1>

      <BlogArticleEditForm
        authorId={query.viewer?.id}
        isDisabled={isMutationInFlight}
        userAutoComplete={query}
        onSubmit={({ data, image }) => {
          const variables: BlogArticleCreatePageMutation$variables = {
            input: {
              title: data.title,
              content: data.content,
              shortDescription: data.shortDescription,
              authorsIds: data.authorsIds || null,
              seoTitle: data.seoTitle || null,
              seoDescription: data.seoDescription || null,
            },
            connections: [
              ConnectionHandler.getConnectionID(
                "root",
                "BlogArticlesFragment_blogArticles",
              ),
            ],
          };
          variables.input.image = image.variable;
          const uploadables: UploadableMap = {};
          if (image.uploadable) {
            uploadables["variables.input.image"] = image.uploadable;
          }

          commitMutation({
            variables,
            uploadables,
            onError: (error) => {
              logger.error(error);
              toast.error(
                intl.formatMessage({
                  defaultMessage: "Failed to create the article.",
                }),
              );
            },
            onCompleted: ({ createBlogArticle: { node } }, _) => {
              navigate(getBlogArticlePath(node));
            },
          });
        }}
      />
    </div>
  );
}
