/**
 * @generated SignedSource<<6899a57346488649eaa15032a85d6b07>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BlogArticlesPageQuery$variables = Record<PropertyKey, never>;
export type BlogArticlesPageQuery$data = {
  readonly viewer: {
    readonly canCreateArticle: boolean;
  };
  readonly " $fragmentSpreads": FragmentRefs<"BlogArticlesFragment">;
};
export type BlogArticlesPageQuery = {
  response: BlogArticlesPageQuery$data;
  variables: BlogArticlesPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": "canCreateArticle",
  "args": [
    {
      "kind": "Literal",
      "name": "action",
      "value": "CREATE_BLOG_ARTICLE"
    }
  ],
  "kind": "ScalarField",
  "name": "can",
  "storageKey": "can(action:\"CREATE_BLOG_ARTICLE\")"
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "BlogArticlesPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/)
        ],
        "storageKey": null
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "BlogArticlesFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "BlogArticlesPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "BlogArticleConnection",
        "kind": "LinkedField",
        "name": "blogArticles",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BlogArticleEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BlogArticle",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "shortDescription",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "image",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "blurHash",
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Topic",
                    "kind": "LinkedField",
                    "name": "topic",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "author",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          {
                            "kind": "TypeDiscriminator",
                            "abstractKey": "__isEntity"
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "username",
                            "storageKey": null
                          },
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "displayName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "imageThumbnail",
                            "storageKey": null
                          },
                          (v1/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v1/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": "viewerCanEdit",
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "action",
                        "value": "UPDATE_BLOG_ARTICLE"
                      }
                    ],
                    "kind": "ScalarField",
                    "name": "viewerCan",
                    "storageKey": "viewerCan(action:\"UPDATE_BLOG_ARTICLE\")"
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "blogArticles(first:10)"
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "filters": null,
        "handle": "connection",
        "key": "BlogArticlesFragment_blogArticles",
        "kind": "LinkedHandle",
        "name": "blogArticles"
      }
    ]
  },
  "params": {
    "cacheID": "f1bf3fec39ceac116c0abca316881d93",
    "id": null,
    "metadata": {},
    "name": "BlogArticlesPageQuery",
    "operationKind": "query",
    "text": "query BlogArticlesPageQuery {\n  viewer @ifAllowed {\n    canCreateArticle: can(action: CREATE_BLOG_ARTICLE)\n    id\n  }\n  ...BlogArticlesFragment\n}\n\nfragment BlogArticleCardFragment on BlogArticle {\n  id\n  title\n  shortDescription\n  image\n  blurHash\n  createdAt\n  topic {\n    author {\n      __typename\n      ...EntityAvatarFragment\n      id\n    }\n    id\n  }\n  viewerCanEdit: viewerCan(action: UPDATE_BLOG_ARTICLE)\n}\n\nfragment BlogArticlesFragment on Query {\n  blogArticles(first: 10) {\n    edges {\n      node {\n        id\n        ...BlogArticleCardFragment\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment EntityAvatarFragment on Entity {\n  __isEntity: __typename\n  username\n  createdAt\n  ...EntityProfilePicFragment_49kWkb\n}\n\nfragment EntityProfilePicFragment_49kWkb on Entity {\n  __isEntity: __typename\n  displayName\n  imageThumbnail\n}\n"
  }
};
})();

(node as any).hash = "3ac8b7c6aaebcee598946add9953b700";

export default node;
