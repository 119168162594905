import { graphql, usePaginationFragment } from "react-relay";
import { BlogArticleCard } from "./BlogArticleCard";
import { BlogArticlesFragment$key } from "./__generated__/BlogArticlesFragment.graphql";
import { cn } from "../utils/tailwind";

const Fragment = graphql`
  fragment BlogArticlesFragment on Query
  @refetchable(queryName: "BlogArticlesFragmentPaginationQuery")
  @argumentDefinitions(
    cursor: { type: "String" }
    count: { type: "Int", defaultValue: 10 }
  ) {
    blogArticles(first: $count, after: $cursor)
      @connection(key: "BlogArticlesFragment_blogArticles") {
      edges {
        node {
          id
          ...BlogArticleCardFragment
        }
      }
    }
  }
`;

export interface BlogArticlesProps {
  query: BlogArticlesFragment$key;
}

export function BlogArticles(props: BlogArticlesProps) {
  const query = usePaginationFragment(Fragment, props.query);

  return (
    <div
      className={cn(
        "grid-cols-1 gap-24",
        "grid md:gap-y-12 md:gap-x-8 md:grid-cols-3",
      )}
    >
      {query.data.blogArticles.edges.map(({ node }) => (
        <BlogArticleCard key={node.id} article={node} className="sm:w-full" />
      ))}
    </div>
  );
}
