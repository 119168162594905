/**
 * @generated SignedSource<<ba21d0d94c56b0eded24c05835ca0ff3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ForumOwnerKind = "BLOG" | "COMPETITION" | "EVENT" | "TOP_LEVEL";
import { FragmentRefs } from "relay-runtime";
export type ForumHelmetFragment$data = {
  readonly createdAt: string;
  readonly forumTopics: {
    readonly nodes: ReadonlyArray<{
      readonly author: {
        readonly displayName: string;
        readonly username: string;
      };
      readonly createdAt: string;
      readonly description: string | null | undefined;
      readonly id: string;
      readonly title: string;
    }>;
  };
  readonly id: string;
  readonly owner: {
    readonly ownerKind: ForumOwnerKind;
    readonly slug: string;
  };
  readonly shortDescription: string;
  readonly slug: string;
  readonly title: string;
  readonly " $fragmentType": "ForumHelmetFragment";
};
export type ForumHelmetFragment$key = {
  readonly " $data"?: ForumHelmetFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ForumHelmetFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ForumHelmetFragment",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "owner",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ownerKind",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shortDescription",
      "storageKey": null
    },
    (v3/*: any*/),
    {
      "alias": "forumTopics",
      "args": [
        {
          "kind": "Literal",
          "name": "last",
          "value": 10
        },
        {
          "kind": "Literal",
          "name": "order",
          "value": "HOT"
        }
      ],
      "concreteType": "TopicConnection",
      "kind": "LinkedField",
      "name": "topics",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Topic",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "description",
              "storageKey": null
            },
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "author",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "displayName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "username",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "topics(last:10,order:\"HOT\")"
    }
  ],
  "type": "Forum",
  "abstractKey": null
};
})();

(node as any).hash = "41c576e14c5a4f6164027bcde06367f0";

export default node;
