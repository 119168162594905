/**
 * @generated SignedSource<<6eeb891bb19a46c13a45c0743cc23bf8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BlogArticleCardFragment$data = {
  readonly blurHash: string | null | undefined;
  readonly createdAt: string;
  readonly id: string;
  readonly image: any | null | undefined;
  readonly shortDescription: string;
  readonly title: string;
  readonly topic: {
    readonly author: {
      readonly " $fragmentSpreads": FragmentRefs<"EntityAvatarFragment">;
    };
  };
  readonly viewerCanEdit: boolean;
  readonly " $fragmentType": "BlogArticleCardFragment";
};
export type BlogArticleCardFragment$key = {
  readonly " $data"?: BlogArticleCardFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"BlogArticleCardFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BlogArticleCardFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shortDescription",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "image",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "blurHash",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Topic",
      "kind": "LinkedField",
      "name": "topic",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "author",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "EntityAvatarFragment"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "viewerCanEdit",
      "args": [
        {
          "kind": "Literal",
          "name": "action",
          "value": "UPDATE_BLOG_ARTICLE"
        }
      ],
      "kind": "ScalarField",
      "name": "viewerCan",
      "storageKey": "viewerCan(action:\"UPDATE_BLOG_ARTICLE\")"
    }
  ],
  "type": "BlogArticle",
  "abstractKey": null
};

(node as any).hash = "e6949b91a8971fc1c49ee6ab85cb4ad3";

export default node;
