import React, { useCallback, useState } from "react";
import { useIntl } from "react-intl";
import { Dropdown } from "./Dropdown";
import { useSearchParams } from "react-router-dom";
import {
  VotableOrder,
  formatVotableOrder,
  VOTABLE_ORDERS,
  DEFAULT_VOTABLE_ORDER,
} from "../utils/votableOrder";
import { MdArrowDropDown } from "react-icons/md";

interface VotableOrderSelectTriggerProps {
  value: VotableOrder;
}

const VotableOrderSelectTrigger = React.forwardRef<
  HTMLButtonElement,
  VotableOrderSelectTriggerProps
>(({ value, ...props }, ref) => {
  const intl = useIntl();
  return (
    <button className="hover:cursor-pointer" ref={ref} {...props}>
      {formatVotableOrder(intl, value)}{" "}
      <MdArrowDropDown className="inline-block" />
    </button>
  );
});

export interface VotableOrderSelectProps {
  value: VotableOrder;
  onChange: (value: VotableOrder) => void;
}

export default function VotableOrderSelect({
  value,
  onChange,
}: VotableOrderSelectProps) {
  const intl = useIntl();
  return (
    <Dropdown autoCloses trigger={<VotableOrderSelectTrigger value={value} />}>
      <Dropdown.List>
        {VOTABLE_ORDERS.map((order) => (
          <Dropdown.Item
            key={order}
            onClick={() => onChange(order)}
            selected={order === value}
          >
            {formatVotableOrder(intl, order)}
          </Dropdown.Item>
        ))}
      </Dropdown.List>
    </Dropdown>
  );
}

export function useVotableOrder(
  initial: VotableOrder = DEFAULT_VOTABLE_ORDER,
  queryParamName = "order",
): [VotableOrder, (order: VotableOrder) => void] {
  const [_, setSearchParams] = useSearchParams();
  const [state, setState] = useState(initial);
  const updateState = useCallback(
    (newOrder: VotableOrder) => {
      setState(newOrder);
      setSearchParams(
        { [queryParamName]: newOrder },
        { preventScrollReset: true },
      );
    },
    [setState, setSearchParams, queryParamName],
  );
  return [state, updateState];
}
