import { FormattedMessage, useIntl } from "react-intl";
import { useMutation, graphql } from "react-relay";
import { useNavigate } from "react-router-dom";
import { useTopicEditPageQuery } from "./loaders/TopicEditPage";
import TopicDeleteButton from "../components/TopicDeleteButton";
import TopicEditForm from "../components/TopicEditForm";
import ErrorPage from "./ErrorPage";
import {
  TopicEditPageMutation$variables,
  TopicEditPageMutation as TopicEditPageMutationType,
} from "./__generated__/TopicEditPageMutation.graphql";
import { logger } from "../common/logger";
import { getTopicPath } from "../utils/routing";
import { useLocation } from "react-router-dom";
import {
  DEFAULT_VOTABLE_ORDER,
  TopicVotableOrderContext,
} from "../utils/votableOrder";

const TopicEditPageMutation = graphql`
  mutation TopicEditPageMutation($id: ID!, $input: UpdateTopicInput!) {
    updateTopic(id: $id, input: $input) {
      id
      title
      description
      forum {
        slug
        owner {
          slug
          ownerKind
        }
      }
    }
  }
`;

export default function TopicEditPage() {
  const intl = useIntl();
  const navigate = useNavigate();
  const {
    query: { node: topic },
  } = useTopicEditPageQuery();
  const {
    state: { order = DEFAULT_VOTABLE_ORDER },
  } = useLocation();
  const [commitMutation, isMutationInFlight] =
    useMutation<TopicEditPageMutationType>(TopicEditPageMutation);

  if (!topic) {
    return (
      <ErrorPage
        status={404}
        message={intl.formatMessage({
          defaultMessage: "Topic not found",
        })}
      />
    );
  }

  return (
    <TopicVotableOrderContext.Provider value={order}>
      <div>
        <h1 className="text-xl font-bold pb-4 font-poppins">
          <FormattedMessage defaultMessage={"Edit Topic"} />
        </h1>
        <TopicEditForm
          isUpdating
          defaultValues={topic}
          onSubmit={({ data, setFormError, dirtyFields }) => {
            const variables: TopicEditPageMutation$variables = {
              id: topic.id!,
              input: {
                title: dirtyFields.title ? data.title : undefined,
                description: dirtyFields.description
                  ? data.description || null
                  : undefined,
              },
            };
            commitMutation({
              variables,
              onError: (error) => {
                logger.error(error);
                setFormError(
                  intl.formatMessage({
                    defaultMessage: "Could not update topic :(",
                  }),
                );
              },
              onCompleted: ({ updateTopic: topic }) => {
                navigate(getTopicPath(topic));
              },
            });
          }}
          isDisabled={isMutationInFlight}
          submitLabel={<FormattedMessage defaultMessage="Save" />}
        />
        <TopicDeleteButton topic={topic} />
      </div>
    </TopicVotableOrderContext.Provider>
  );
}
