import { graphql, Environment, useFragment } from "react-relay";
import { LoaderArgs } from "../../utils/route";
import { TopicPageQuery as TopicPageQueryType } from "./__generated__/TopicPageQuery.graphql";
import { preload, usePreloaded } from "../../utils/relay";
import {
  getVotableOrderParam,
  DEFAULT_VOTABLE_ORDER,
} from "../../utils/votableOrder";
import { TopicPageFragment$key } from "./__generated__/TopicPageFragment.graphql";

export type { TopicPageQuery as TopicPageQueryType } from "./__generated__/TopicPageQuery.graphql";

const Fragment = graphql`
  fragment TopicPageFragment on Topic {
    id
    title
    url
    createdAt
    forum {
      slug
      owner {
        slug
        ownerKind
      }
    }
    author {
      username
      ...EntityProfilePicFragment @arguments(thumbnail: true)
    }
    description
    viewerCanEdit: viewerCan(action: UPDATE_TOPIC)
    ...TopicCommentsFormTopicFragment
    ...TopicCommentsFragment @arguments(order: $order)
    ...VoteDisplayFragment
    ...SubjectSubscriptionButtonFragment
    ...TopicHelmetFragment
    ...TopicLinkFragment
  }
`;

export const TopicPageQuery = graphql`
  query TopicPageQuery($id: ID!, $order: VotableOrder!) {
    viewer @ifAllowed {
      ...TopicCommentsFormViewerFragment
    }
    node(id: $id) {
      __typename
      ... on Topic {
        ...TopicPageFragment
      }
    }
  }
`;

export const topicPageQueryLoader = (environment: Environment) => {
  return ({ params: { topicId: id }, request }: LoaderArgs) => {
    const order = getVotableOrderParam(request) || DEFAULT_VOTABLE_ORDER;
    if (!id) {
      throw new Response("No id", { status: 500 });
    }
    return preload<TopicPageQueryType>(environment, TopicPageQuery, {
      id,
      order,
    });
  };
};

export const useTopicPageQuery = () => {
  const { query, ...preloaded } = usePreloaded<TopicPageQueryType>();
  const topic = useFragment<TopicPageFragment$key>(
    Fragment,
    query.node.__typename === "Topic" ? query.node : null,
  );
  return { ...preloaded, topic, query };
};
