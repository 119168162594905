import { graphql, Environment } from "react-relay";
import { preload, usePreloaded } from "../../utils/relay";
import { BlogArticleCreatePageQuery as BlogArticleCreatePageQueryType } from "./__generated__/BlogArticleCreatePageQuery.graphql";

export const BlogArticleCreatePageQuery = graphql`
  query BlogArticleCreatePageQuery {
    ...UserAutocompleteFragment
    viewer @ifAllowed {
      id
    }
  }
`;

export const blogArticleCeatePageQueryLoader = (environment: Environment) => {
  return () => {
    return preload<BlogArticleCreatePageQueryType>(
      environment,
      BlogArticleCreatePageQuery,
    );
  };
};

export const useBlogArticleCreatePageQuery = () =>
  usePreloaded<BlogArticleCreatePageQueryType>();
