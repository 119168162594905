import { useIntl } from "react-intl";
import { useBlogArticlePageQuery } from "./loaders/BlogArticlePage";
import ErrorPage from "./ErrorPage";
import BlogArticle from "../components/BlogArticle";
import { useExpectedLocationPath } from "../utils/hooks";
import { getBlogArticlePath } from "../utils/routing";

export default function BlogArticlePage() {
  const intl = useIntl();
  const {
    query: { blogArticleById, viewer },
    variables: { order },
  } = useBlogArticlePageQuery();

  useExpectedLocationPath(
    blogArticleById ? getBlogArticlePath(blogArticleById) : null,
  );

  if (!blogArticleById) {
    return (
      <ErrorPage
        status={404}
        message={intl.formatMessage({
          defaultMessage: "Article not found",
        })}
      />
    );
  }

  return (
    <BlogArticle
      article={blogArticleById}
      viewer={viewer}
      initialCommentOrder={order}
    />
  );
}
