/**
 * @generated SignedSource<<261dead5be56db2dc426b768e62cb0ff>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ForumOwnerKind = "BLOG" | "COMPETITION" | "EVENT" | "TOP_LEVEL";
import { FragmentRefs } from "relay-runtime";
export type TopicPageFragment$data = {
  readonly author: {
    readonly username: string;
    readonly " $fragmentSpreads": FragmentRefs<"EntityProfilePicFragment">;
  };
  readonly createdAt: string;
  readonly description: string | null | undefined;
  readonly forum: {
    readonly owner: {
      readonly ownerKind: ForumOwnerKind;
      readonly slug: string;
    };
    readonly slug: string;
  };
  readonly id: string;
  readonly title: string;
  readonly url: string | null | undefined;
  readonly viewerCanEdit: boolean;
  readonly " $fragmentSpreads": FragmentRefs<"SubjectSubscriptionButtonFragment" | "TopicCommentsFormTopicFragment" | "TopicCommentsFragment" | "TopicHelmetFragment" | "TopicLinkFragment" | "VoteDisplayFragment">;
  readonly " $fragmentType": "TopicPageFragment";
};
export type TopicPageFragment$key = {
  readonly " $data"?: TopicPageFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"TopicPageFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "order"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "TopicPageFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Forum",
      "kind": "LinkedField",
      "name": "forum",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "owner",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "ownerKind",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "author",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "username",
          "storageKey": null
        },
        {
          "args": [
            {
              "kind": "Literal",
              "name": "thumbnail",
              "value": true
            }
          ],
          "kind": "FragmentSpread",
          "name": "EntityProfilePicFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": "viewerCanEdit",
      "args": [
        {
          "kind": "Literal",
          "name": "action",
          "value": "UPDATE_TOPIC"
        }
      ],
      "kind": "ScalarField",
      "name": "viewerCan",
      "storageKey": "viewerCan(action:\"UPDATE_TOPIC\")"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TopicCommentsFormTopicFragment"
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "order",
          "variableName": "order"
        }
      ],
      "kind": "FragmentSpread",
      "name": "TopicCommentsFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "VoteDisplayFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SubjectSubscriptionButtonFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TopicHelmetFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TopicLinkFragment"
    }
  ],
  "type": "Topic",
  "abstractKey": null
};
})();

(node as any).hash = "38d430a066493e03666048a254611291";

export default node;
