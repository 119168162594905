import { graphql, useFragment } from "react-relay";
import { Link } from "react-router-dom";
import { ForumFragment$key } from "./__generated__/ForumFragment.graphql";
import AspectDiv from "./AspectDiv";
import defaultIcon from "../assets/default-forum-icon.svg";
import { FormattedMessage } from "react-intl";
import { TimeAgo } from "./TimeAgo";
import { getForumPath } from "../utils/routing";

const ForumFragment = graphql`
  fragment ForumFragment on Forum {
    slug
    title
    shortDescription
    icon
    owner {
      slug
      ownerKind
    }
    topics(first: 1, order: NEWEST) {
      nodes {
        createdAt
        author {
          username
        }
      }
    }
    viewerCanEdit: viewerCan(action: UPDATE_FORUM)
  }
`;

interface Props {
  forum: ForumFragment$key;
}

export default function Forum({ forum: forumFragment }: Props) {
  const forum = useFragment(ForumFragment, forumFragment);
  const [topic] = forum.topics.nodes;
  const url = getForumPath(forum);
  return (
    <div className="flex flex-row space-x-6 items-center hover:cursor-pointer">
      <Link className="w-24 shrink-0" to={url}>
        <AspectDiv className="rounded-lg" ratio={1}>
          <img
            src={forum.icon || defaultIcon}
            className="h-full w-full rounded-t-md object-cover object-center"
            alt={forum.title}
          />
        </AspectDiv>
      </Link>
      <div className="flex-grow">
        <Link to={url}>
          <h1 className="text-xl font-bold font-poppins">{forum.title}</h1>
        </Link>
        <Link to={url}>
          <h2 className="text-l">{forum.shortDescription}</h2>
        </Link>
        {topic && (
          <div className="text-sm" suppressHydrationWarning>
            <FormattedMessage
              defaultMessage="last post {createdAt} by {author}"
              values={{
                author: (
                  <Link to={`/${topic.author.username}`}>
                    {topic.author.username}
                  </Link>
                ),
                createdAt: <TimeAgo createdAt={new Date(topic.createdAt)} />,
              }}
            />
          </div>
        )}
        {forum.viewerCanEdit && (
          <Link to={`${url}/edit`}>
            <FormattedMessage defaultMessage="Edit" />
          </Link>
        )}
      </div>
    </div>
  );
}
