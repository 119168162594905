import { Helmet } from "react-helmet-async";
import { graphql, useFragment } from "react-relay";
import {
  TopicHelmetFragment$data,
  TopicHelmetFragment$key,
} from "./__generated__/TopicHelmetFragment.graphql";
import { LocationContextValue, useLocation } from "../utils/location";
import { getTopicPath } from "../utils/routing";
import { MetaLayout, MetaLayoutProps } from "../common/MetaLayout";
import { useIntl } from "react-intl";

const Fragment = graphql`
  fragment TopicHelmetFragment on Topic {
    id
    title
    description
    createdAt
    votes
    author {
      username
      displayName
    }
    forum {
      title
      slug
      owner {
        slug
        ownerKind
      }
    }
    lastComments: comments(last: 10) {
      nodes {
        content
        createdAt
        votes
        author {
          username
          displayName
        }
      }
    }
  }
`;

export interface TopicHelmetProps
  extends Pick<
    MetaLayoutProps,
    "metaTitle" | "metaDescription" | "metaImageAlt" | "metaImageUrl"
  > {
  topic: TopicHelmetFragment$key;
}

export default function TopicHelmet(props: TopicHelmetProps) {
  const intl = useIntl();
  const topic = useFragment(Fragment, props.topic);
  const location = useLocation();
  const forumTitle = topic.forum?.title ?? undefined;

  const metaTitle = forumTitle
    ? intl.formatMessage(
        { defaultMessage: "{topicTitle} | {forumTitle}" },
        { topicTitle: topic.title, forumTitle },
      )
    : topic.title;
  return (
    <MetaLayout
      metaTitle={props.metaTitle ?? metaTitle}
      metaDescription={props.metaDescription ?? topic.description}
      metaImageUrl={props.metaImageUrl}
      metaImageAlt={props.metaImageUrl}
    >
      <Helmet>
        <meta
          name="twitter:label1"
          content={intl.formatMessage({ defaultMessage: "Written by" })}
        />
        <meta name="twitter:data1" content={topic.author.displayName} />
        <meta
          name="twitter:label2"
          content={intl.formatMessage({ defaultMessage: "Votes" })}
        />
        <meta name="twitter:data2" content={topic.votes.toString()} />
        <script type="application/ld+json">
          {JSON.stringify(formatDiscussionForumPosting(topic, location))}
        </script>
      </Helmet>
    </MetaLayout>
  );
}

function getEntityProfilePath(username: string) {
  return `/${username}`;
}

function formatDiscussionForumPosting(
  topic: TopicHelmetFragment$data,
  location: LocationContextValue,
) {
  const topicUrl = new URL(getTopicPath(topic), location.origin).toString();

  return {
    "@context": "https://schema.org",
    "@type": "DiscussionForumPosting",
    url: topicUrl,
    mainEntityOfPage: topicUrl,
    headline: topic.title,
    text: topic.description,
    author: {
      "@type": "Person",
      name: topic.author.displayName,
      url: new URL(
        getEntityProfilePath(topic.author.username),
        location.origin,
      ).toString(),
    },
    datePublished: topic.createdAt,
    interactionStatistic: {
      "@type": "InteractionCounter",
      interactionType: "https://schema.org/LikeAction",
      userInteractionCount: topic.votes,
    },
    comment: topic.lastComments.nodes.map((comment) => ({
      "@type": "Comment",
      text: comment.content,
      author: {
        "@type": "Person",
        name: comment.author.displayName,
        url: new URL(
          getEntityProfilePath(topic.author.username),
          location.origin,
        ).toString(),
      },
      datePublished: comment.createdAt,
      interactionStatistic: {
        "@type": "InteractionCounter",
        interactionType: "https://schema.org/LikeAction",
        userInteractionCount: comment.votes,
      },
    })),
  };
}
