/**
 * @generated SignedSource<<9313486afd1dd7b14792ad8ce1860fc7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserCommentsPageQuery$variables = {
  username: string;
};
export type UserCommentsPageQuery$data = {
  readonly entityByUsername: {
    readonly " $fragmentSpreads": FragmentRefs<"UserCommentsPageFragment">;
  } | null | undefined;
};
export type UserCommentsPageQuery = {
  response: UserCommentsPageQuery$data;
  variables: UserCommentsPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "username"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "username",
    "variableName": "username"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "kind": "Literal",
  "name": "order",
  "value": "NEWEST"
},
v5 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  },
  (v4/*: any*/)
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "imageThumbnail",
  "storageKey": null
},
v11 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "votes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EntityVote",
      "kind": "LinkedField",
      "name": "voted",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "score",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "viewerCanVote",
      "args": [
        {
          "kind": "Literal",
          "name": "action",
          "value": "PUBLISH_VOTE"
        }
      ],
      "kind": "ScalarField",
      "name": "viewerCan",
      "storageKey": "viewerCan(action:\"PUBLISH_VOTE\")"
    }
  ],
  "type": "Votable",
  "abstractKey": "__isVotable"
},
v12 = [
  (v3/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserCommentsPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "entityByUsername",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UserCommentsPageFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserCommentsPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "entityByUsername",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v5/*: any*/),
                "concreteType": "CommentConnection",
                "kind": "LinkedField",
                "name": "comments",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CommentEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Comment",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Topic",
                            "kind": "LinkedField",
                            "name": "topic",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "title",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "url",
                                "storageKey": null
                              },
                              (v6/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "commentCount",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Forum",
                                "kind": "LinkedField",
                                "name": "forum",
                                "plural": false,
                                "selections": [
                                  (v7/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": null,
                                    "kind": "LinkedField",
                                    "name": "owner",
                                    "plural": false,
                                    "selections": [
                                      (v2/*: any*/),
                                      (v7/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "ownerKind",
                                        "storageKey": null
                                      },
                                      (v3/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  (v3/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "author",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  (v8/*: any*/),
                                  {
                                    "kind": "TypeDiscriminator",
                                    "abstractKey": "__isEntity"
                                  },
                                  (v9/*: any*/),
                                  (v10/*: any*/),
                                  (v3/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": [
                                  {
                                    "kind": "Literal",
                                    "name": "first",
                                    "value": 1
                                  },
                                  (v4/*: any*/)
                                ],
                                "concreteType": "CommentConnection",
                                "kind": "LinkedField",
                                "name": "comments",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Comment",
                                    "kind": "LinkedField",
                                    "name": "nodes",
                                    "plural": true,
                                    "selections": [
                                      (v6/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "User",
                                        "kind": "LinkedField",
                                        "name": "author",
                                        "plural": false,
                                        "selections": [
                                          (v8/*: any*/),
                                          (v9/*: any*/),
                                          (v3/*: any*/)
                                        ],
                                        "storageKey": null
                                      },
                                      (v3/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": "comments(first:1,order:\"NEWEST\")"
                              },
                              (v11/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": "viewerCanDelete",
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "action",
                                "value": "DELETE_COMMENT"
                              }
                            ],
                            "kind": "ScalarField",
                            "name": "viewerCan",
                            "storageKey": "viewerCan(action:\"DELETE_COMMENT\")"
                          },
                          {
                            "alias": "viewerCanUpdate",
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "action",
                                "value": "UPDATE_COMMENT"
                              }
                            ],
                            "kind": "ScalarField",
                            "name": "viewerCan",
                            "storageKey": "viewerCan(action:\"UPDATE_COMMENT\")"
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "content",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "edited",
                            "storageKey": null
                          },
                          (v6/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Topic",
                            "kind": "LinkedField",
                            "name": "topic",
                            "plural": false,
                            "selections": (v12/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Comment",
                            "kind": "LinkedField",
                            "name": "parent",
                            "plural": false,
                            "selections": (v12/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "author",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              (v8/*: any*/),
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  (v9/*: any*/),
                                  (v10/*: any*/)
                                ],
                                "type": "Entity",
                                "abstractKey": "__isEntity"
                              }
                            ],
                            "storageKey": null
                          },
                          (v2/*: any*/),
                          (v11/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "comments(first:10,order:\"NEWEST\")"
              },
              {
                "alias": null,
                "args": (v5/*: any*/),
                "filters": [
                  "order"
                ],
                "handle": "connection",
                "key": "UserCommentsPageFragment_comments",
                "kind": "LinkedHandle",
                "name": "comments"
              }
            ],
            "type": "User",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "92493fb3b53e5953ed07edff036ce9a3",
    "id": null,
    "metadata": {},
    "name": "UserCommentsPageQuery",
    "operationKind": "query",
    "text": "query UserCommentsPageQuery(\n  $username: String!\n) {\n  entityByUsername(username: $username) {\n    __typename\n    ...UserCommentsPageFragment\n    id\n  }\n}\n\nfragment CommentDeleteFragment on Comment {\n  id\n  author {\n    id\n  }\n  topic {\n    id\n  }\n  parent {\n    id\n  }\n}\n\nfragment CommentDisplayFragment on Comment {\n  viewerCanDelete: viewerCan(action: DELETE_COMMENT)\n  viewerCanUpdate: viewerCan(action: UPDATE_COMMENT)\n  id\n  content\n  edited\n  createdAt\n  topic {\n    id\n  }\n  parent {\n    id\n  }\n  ...CommentDeleteFragment\n  author {\n    id\n    username\n    ...EntityProfilePicFragment_49kWkb\n  }\n  ...VoteDisplayFragment\n}\n\nfragment EntityProfilePicFragment_49kWkb on Entity {\n  __isEntity: __typename\n  displayName\n  imageThumbnail\n}\n\nfragment TopicListItemFragment on Topic {\n  id\n  title\n  url\n  createdAt\n  commentCount\n  forum {\n    slug\n    owner {\n      __typename\n      slug\n      ownerKind\n      id\n    }\n    id\n  }\n  author {\n    __typename\n    username\n    ...EntityProfilePicFragment_49kWkb\n    id\n  }\n  comments(first: 1, order: NEWEST) {\n    nodes {\n      createdAt\n      author {\n        username\n        displayName\n        id\n      }\n      id\n    }\n  }\n  ...VoteDisplayFragment\n}\n\nfragment UserCommentsPageFragment on User {\n  comments(first: 10, order: NEWEST) {\n    edges {\n      node {\n        id\n        topic @ifAllowed {\n          id\n          ...TopicListItemFragment\n        }\n        ...CommentDisplayFragment\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  id\n}\n\nfragment VoteDisplayFragment on Votable {\n  __isVotable: __typename\n  id\n  votes\n  voted @ifAllowed {\n    score\n  }\n  viewerCanVote: viewerCan(action: PUBLISH_VOTE)\n}\n"
  }
};
})();

(node as any).hash = "c8e4d4ea5bc67c95db9deee3eccd256e";

export default node;
