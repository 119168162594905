/**
 * @generated SignedSource<<1266374a0c3ac70dfd0e531ab0913e8e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserTopicsPageQuery$variables = {
  username: string;
};
export type UserTopicsPageQuery$data = {
  readonly entityByUsername: {
    readonly " $fragmentSpreads": FragmentRefs<"UserTopicsPageFragment">;
  } | null | undefined;
};
export type UserTopicsPageQuery = {
  response: UserTopicsPageQuery$data;
  variables: UserTopicsPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "username"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "username",
    "variableName": "username"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "kind": "Literal",
  "name": "order",
  "value": "NEWEST"
},
v5 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  },
  (v4/*: any*/)
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserTopicsPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "entityByUsername",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UserTopicsPageFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserTopicsPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "entityByUsername",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v5/*: any*/),
                "concreteType": "TopicConnection",
                "kind": "LinkedField",
                "name": "topics",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TopicEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Topic",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "title",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "url",
                            "storageKey": null
                          },
                          (v6/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "commentCount",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Forum",
                            "kind": "LinkedField",
                            "name": "forum",
                            "plural": false,
                            "selections": [
                              (v7/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "owner",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  (v7/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "ownerKind",
                                    "storageKey": null
                                  },
                                  (v3/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v3/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "author",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v8/*: any*/),
                              {
                                "kind": "TypeDiscriminator",
                                "abstractKey": "__isEntity"
                              },
                              (v9/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "imageThumbnail",
                                "storageKey": null
                              },
                              (v3/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "first",
                                "value": 1
                              },
                              (v4/*: any*/)
                            ],
                            "concreteType": "CommentConnection",
                            "kind": "LinkedField",
                            "name": "comments",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Comment",
                                "kind": "LinkedField",
                                "name": "nodes",
                                "plural": true,
                                "selections": [
                                  (v6/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "User",
                                    "kind": "LinkedField",
                                    "name": "author",
                                    "plural": false,
                                    "selections": [
                                      (v8/*: any*/),
                                      (v9/*: any*/),
                                      (v3/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  (v3/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": "comments(first:1,order:\"NEWEST\")"
                          },
                          (v2/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "votes",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "EntityVote",
                                "kind": "LinkedField",
                                "name": "voted",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "score",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": "viewerCanVote",
                                "args": [
                                  {
                                    "kind": "Literal",
                                    "name": "action",
                                    "value": "PUBLISH_VOTE"
                                  }
                                ],
                                "kind": "ScalarField",
                                "name": "viewerCan",
                                "storageKey": "viewerCan(action:\"PUBLISH_VOTE\")"
                              }
                            ],
                            "type": "Votable",
                            "abstractKey": "__isVotable"
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "topics(first:10,order:\"NEWEST\")"
              },
              {
                "alias": null,
                "args": (v5/*: any*/),
                "filters": [
                  "order"
                ],
                "handle": "connection",
                "key": "UserTopicsPageFragment_topics",
                "kind": "LinkedHandle",
                "name": "topics"
              }
            ],
            "type": "User",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d92f24bd661d09e5abb0252cbb95ddbb",
    "id": null,
    "metadata": {},
    "name": "UserTopicsPageQuery",
    "operationKind": "query",
    "text": "query UserTopicsPageQuery(\n  $username: String!\n) {\n  entityByUsername(username: $username) {\n    __typename\n    ...UserTopicsPageFragment\n    id\n  }\n}\n\nfragment EntityProfilePicFragment_49kWkb on Entity {\n  __isEntity: __typename\n  displayName\n  imageThumbnail\n}\n\nfragment TopicListItemFragment on Topic {\n  id\n  title\n  url\n  createdAt\n  commentCount\n  forum {\n    slug\n    owner {\n      __typename\n      slug\n      ownerKind\n      id\n    }\n    id\n  }\n  author {\n    __typename\n    username\n    ...EntityProfilePicFragment_49kWkb\n    id\n  }\n  comments(first: 1, order: NEWEST) {\n    nodes {\n      createdAt\n      author {\n        username\n        displayName\n        id\n      }\n      id\n    }\n  }\n  ...VoteDisplayFragment\n}\n\nfragment UserTopicsPageFragment on User {\n  topics(first: 10, order: NEWEST) {\n    edges {\n      node {\n        id\n        ...TopicListItemFragment\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  id\n}\n\nfragment VoteDisplayFragment on Votable {\n  __isVotable: __typename\n  id\n  votes\n  voted @ifAllowed {\n    score\n  }\n  viewerCanVote: viewerCan(action: PUBLISH_VOTE)\n}\n"
  }
};
})();

(node as any).hash = "e1c724172151edf5580be3abc19284fb";

export default node;
