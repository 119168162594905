import { FormattedMessage, useIntl } from "react-intl";
import {
  ConnectionHandler,
  graphql,
  useFragment,
  useMutation,
} from "react-relay";
import { useNavigate } from "react-router-dom";
import { BlogArticleDeleteButtonFragment$key } from "./__generated__/BlogArticleDeleteButtonFragment.graphql";
import { BlogArticleDeleteButtonMutation as BlogArticleDeleteButtonMutationType } from "./__generated__/BlogArticleDeleteButtonMutation.graphql";
import { ConfirmDialog } from "./ConfirmDialog";
import { toast } from "sonner";

const BlogArticleDeleteButtonFragment = graphql`
  fragment BlogArticleDeleteButtonFragment on BlogArticle {
    __id
    id
    title
  }
`;

const BlogArticleDeleteButtonMutation = graphql`
  mutation BlogArticleDeleteButtonMutation($id: ID!, $connections: [ID!]!) {
    deleteBlogArticle(id: $id) @deleteEdge(connections: $connections)
  }
`;

interface Props {
  article: BlogArticleDeleteButtonFragment$key;
}

export default function BlogArticleDeleteButton({
  article: articleFragment,
}: Props) {
  const intl = useIntl();

  const navigate = useNavigate();
  const article = useFragment(BlogArticleDeleteButtonFragment, articleFragment);
  const [commitMutation, isMutationInFlight] =
    useMutation<BlogArticleDeleteButtonMutationType>(
      BlogArticleDeleteButtonMutation,
    );

  const onDelete = (event: React.MouseEvent) => {
    event.stopPropagation();
    if (isMutationInFlight) {
      return;
    }

    commitMutation({
      variables: {
        id: article.id,
        connections: [
          ConnectionHandler.getConnectionID(
            "root",
            "BlogArticlesFragment_blogArticles",
          ),
        ],
      },

      onCompleted: () => {
        toast.success(
          intl.formatMessage({
            defaultMessage: "The article has been successfully deleted",
          }),
        );

        navigate("/blog");
      },

      onError: () => {
        toast.error(
          intl.formatMessage({
            defaultMessage:
              "There was an error while deleting this article: please try again later...",
          }),
        );
      },
    });
  };

  return (
    <ConfirmDialog
      onConfirm={onDelete}
      kind="danger"
      title={<FormattedMessage defaultMessage="Delete the article" />}
      buttonMessage={<FormattedMessage defaultMessage="Delete" />}
      message={
        <FormattedMessage
          defaultMessage="By clicking on the button below, this will permanently delete the article : {articleTitle}."
          values={{ articleTitle: <strong>{article.title}</strong> }}
        />
      }
    />
  );
}
