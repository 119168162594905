import { useBlogArticlesPageQuery } from "./loaders/BlogArticlesPage";

import { FormattedMessage } from "react-intl";
import Button from "../components/Button";
import { BlogArticles } from "../components/BlogArticles";

export default function BlogsPage() {
  const { query } = useBlogArticlesPageQuery();

  return (
    <>
      <div className="flex flex-col items-start gap-2">
        <span className="text-indigo text-sm font-semibold leading-tight">
          <FormattedMessage defaultMessage="Our blog" />
        </span>

        <h1 className="text-gray-950 text-3xl font-semibold leading-10 -ml-1">
          <FormattedMessage defaultMessage="The latest from Quantum world" />
        </h1>

        <h2 className="text-gray-600 text-xl font-normal leading-8">
          <FormattedMessage defaultMessage="The latest quantum news, interviews, technologies, and resources." />
        </h2>
      </div>

      {query.viewer?.canCreateArticle && (
        <Button kind="primary" to="/blog/new">
          <FormattedMessage defaultMessage="Create Article" />
        </Button>
      )}

      <div className="flex flex-col gap-24 mt-12">
        {/*
        <div className="flex flex-col gap-4">
          <p className="text-gray-950  text-xl font-semibold leading-loose">
            <FormattedMessage defaultMessage="Recent blog posts" />
          </p>
          <BlogList blog={forum} kind="latest" />
        </div>
        */}
        <div className="flex flex-col gap-4">
          {/*
          <p className="text-gray-950  text-xl font-semibold leading-loose">
            <FormattedMessage defaultMessage="All blog posts" />
          </p>
          */}

          <BlogArticles query={query} />
        </div>
      </div>
    </>
  );
}
