import { useState } from "react";
import ForumTopics from "../../components/ForumTopics";
import { FormattedMessage } from "react-intl";
import Button from "../../components/Button";
import VotableOrderSelect from "../../components/VotableOrderSelect";
import {
  VotableOrder,
  TopicVotableOrderContext,
} from "../../utils/votableOrder";
import { useAuth } from "../../utils/auth";
import { getForumPath } from "../../utils/routing";
import { graphql } from "relay-runtime";
import { TopicsPageFragment$key } from "./__generated__/TopicsPageFragment.graphql";
import { useFragment } from "react-relay";
import SubjectSubscriptionButton from "../../components/SubjectSubscriptionButton";

const Fragment = graphql`
  fragment TopicsPageFragment on Forum
  @argumentDefinitions(order: { type: "VotableOrder", defaultValue: HOT }) {
    slug
    owner {
      slug
      ownerKind
    }
    viewerCanCreateTopic: viewerCan(action: CREATE_TOPIC)
    ...ForumTopicsFragment @arguments(order: $order)
    ...SubjectSubscriptionButtonFragment
  }
`;

export interface TopicsPageProps {
  forum: TopicsPageFragment$key;
  initialOrder: VotableOrder;
}

export default function TopicsPage({
  forum: forumFragment,
  initialOrder,
}: TopicsPageProps) {
  const { userId } = useAuth();
  const forum = useFragment(Fragment, forumFragment);
  const [order, setOrder] = useState<VotableOrder>(initialOrder);
  return (
    <TopicVotableOrderContext.Provider value={order}>
      <div className="flex flex-row">
        <div className="flex flex-row flex-1 items-baseline gap-4">
          <h1 className="text-2xl font-bold font-poppins">
            <FormattedMessage defaultMessage="Topics" />
          </h1>
          <VotableOrderSelect value={order} onChange={setOrder} />
        </div>
        <div className="flex flex-row gap-4">
          <SubjectSubscriptionButton subject={forum} />
          {userId && forum.viewerCanCreateTopic && (
            <Button to={getForumPath(forum, "new")} state={{ order }}>
              <FormattedMessage defaultMessage="New Topic" />
            </Button>
          )}
        </div>
      </div>
      <div className="pt-4 pb-8">
        <ForumTopics forum={forum} order={order} />
      </div>
    </TopicVotableOrderContext.Provider>
  );
}
